// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cks-jsx": () => import("./../../../src/pages/cks.jsx" /* webpackChunkName: "component---src-pages-cks-jsx" */),
  "component---src-pages-connect-jsx": () => import("./../../../src/pages/connect.jsx" /* webpackChunkName: "component---src-pages-connect-jsx" */),
  "component---src-pages-dus-jsx": () => import("./../../../src/pages/dus.jsx" /* webpackChunkName: "component---src-pages-dus-jsx" */),
  "component---src-pages-group-jsx": () => import("./../../../src/pages/group.jsx" /* webpackChunkName: "component---src-pages-group-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-story-jsx": () => import("./../../../src/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-our-story-jsx" */),
  "component---src-pages-ptdck-jsx": () => import("./../../../src/pages/ptdck.jsx" /* webpackChunkName: "component---src-pages-ptdck-jsx" */),
  "component---src-pages-what-we-do-jsx": () => import("./../../../src/pages/what-we-do.jsx" /* webpackChunkName: "component---src-pages-what-we-do-jsx" */)
}

